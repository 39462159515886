body {
  background: #1a1b26;
}

.collapse-container {
  position: relative;
}
.collapse-button {
  position: absolute;
  top: 0;
  left: -21px;
  background: #1a1b26;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 100;
}
